import { DateTime } from "luxon";
import countries from "public/static/countries";

export default () => {
  const useValidPhone = (number) => {
    const phoneNumber = String(number);
    return !!(
      phoneNumber &&
      phoneNumber.length >= 8 &&
      phoneNumber.length <= 15 &&
      /[\d]+/g.test(phoneNumber)
    );
  };
  const useValidatePassword = (password) => {
    const regPassword = /[\w\d!@#$%^&*()_+|\-={}\[\]~:;"',.<>/?]{8,}/g;
    return regPassword.test(password);
  };
  const useValidateEmail = (email) => {
    const regEmail = /^([^\s])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
    return regEmail.test(email);
  };

  const useJudgeTime = (media) => {
    const { t } = useI18n();
    const now = DateTime.now();
    let start;

    if (Object.keys(media).includes("extendedProgram")) {
      if (media.extendedProgram.startDate) {
        start = media.extendedProgram.startDate;
      }
    } else {
      if (media.startDate) {
        start = media.startDate;
      }
    }
    const startLxn = DateTime.fromMillis(start);
    const inValid = now < startLxn;
    const isToday = now.hasSame(startLxn, "day");
    const formattedTime = isToday
      ? startLxn.toFormat("t")
      : startLxn.toFormat("LLL d, t");
    const preposition = isToday ? "at" : "on";
    const content = `${t(`Available ${preposition}`)} ${formattedTime}`;
    return {
      inValid,
      content,
    };
  };

  const useFindLocation = (country) => {
    if (/(\+?)\d+/.test(country)) {
      console.log("country", country);
      return countries.find(
        (c) => c.dialCode == country || c.dialCode == `+${country}`
      );
    }
    return countries.find(
      (c) => c.isoNation == country || c.isoNation == country?.toUpperCase()
    );
  };
  /**
   * Determine if the return is the same routing path
   * @param {string} backPath
   * @param {string} currentPath
   * @returns boolean
   */
  const useIsSameRoute = (backPath, currentPath) => {
    const paths = [backPath, currentPath].map((it) =>
      it.replace(/^(\/en|\/ar)/, "")
    );
    return new Set(paths).size == 1;
  };
  /**
   * Check if the current path and cookie storage language are consistent
   * @param {string} path
   * @returns boolean
   */
  const useIsChangeLang = (path) => {
    const cookie = useCookie("locale");
    return !path.includes(`/${cookie.value}`);
  };
  /**
   * Prevent input type password from automatically filling in
   * @returns void
   */
  const useStopAutocomplete = () => {
    nextTick(() => {
      const inputs = document.querySelectorAll('input[type="password"]');
      if (inputs && inputs.length > 0) {
        inputs.forEach((it) => it.setAttribute("readonly", true));
        const st = setTimeout(() => {
          clearTimeout(st);
          inputs.forEach((it) => it.removeAttribute("readonly"));
        }, 200);
      }
    });
  };
  /**
   * Delete code to cancel auto focus on the previous input
   * @returns void
   */
  const useDelCancelAutoFocus = (otpInput, currentVal) => {
    const oldOtpArr = otpInput.value.oldOtp.filter(
      (it) => it != "" && it != null
    );
    if (oldOtpArr.length > currentVal.length) {
      otpInput.value.handleOnFocus(currentVal.length + 1);
    }
  };
  return {
    useFindLocation,
    useJudgeTime,
    useValidPhone,
    useValidateEmail,
    useValidatePassword,
    useIsSameRoute,
    useStopAutocomplete,
    useDelCancelAutoFocus,
    useIsChangeLang,
  };
};
