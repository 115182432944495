export default [
  {
    isoNation: "AD",
    nation: "AND",
    dialCode: "+376",
    nameAr: "(+376) أندورا",
    nameEn: "Andorra (+376)",
    noCodeNameAr: "أندورا",
    noCodeNameEn: "Andorra",
  },
  {
    isoNation: "AF",
    nation: "AFG",
    dialCode: "+93",
    nameAr: "(+93) أفغانستان",
    nameEn: "Afghanistan (+93)",
    noCodeNameAr: "أفغانستان",
    noCodeNameEn: "Afghanistan",
  },
  {
    isoNation: "AG",
    nation: "ATG",
    dialCode: "+1",
    nameAr: "(+1268) أنتيغا وبربودا",
    nameEn: "Antigua and Barbuda (+1268)",
    noCodeNameAr: "أنتيغا وبربودا",
    noCodeNameEn: "Antigua and Barbuda",
  },
  {
    isoNation: "AI",
    nation: "AIA",
    dialCode: "+1",
    nameAr: "(+1264) أنغويلا",
    nameEn: "Anguilla (+1264)",
    noCodeNameAr: "أنغويلا",
    noCodeNameEn: "Anguilla",
  },
  {
    isoNation: "AL",
    nation: "ALB",
    dialCode: "+355",
    nameAr: "(+355) ألبانيا",
    nameEn: "Albania (+355)",
    noCodeNameAr: "ألبانيا",
    noCodeNameEn: "Albania",
  },
  {
    isoNation: "AM",
    nation: "ARM",
    dialCode: "+374",
    nameAr: "(+374) أرمينيا",
    nameEn: "Armenia (+374)",
    noCodeNameAr: "أرمينيا",
    noCodeNameEn: "Armenia",
  },
  {
    isoNation: "AN",
    nation: "ANT",
    dialCode: "+599",
    nameAr: "(+599) جزر الأنتيل الهولندية",
    nameEn: "Netherlands Antilles (+599)",
    noCodeNameAr: "جزر الأنتيل الهولندية",
    noCodeNameEn: "Netherlands Antilles",
  },
  {
    isoNation: "AO",
    nation: "AGO",
    dialCode: "+244",
    nameAr: "(+244) أنغولا",
    nameEn: "Angola (+244)",
    noCodeNameAr: "أنغولا",
    noCodeNameEn: "Angola",
  },
  {
    isoNation: "AQ",
    nation: "ATA",
    dialCode: "+672",
    nameAr: "(+672) أنتاركتيكا",
    nameEn: "Antarctica (+672)",
    noCodeNameAr: "أنتاركتيكا",
    noCodeNameEn: "Antarctica",
  },
  {
    isoNation: "Ar",
    nation: "ARG",
    dialCode: "+54",
    nameAr: "(+54) الأرجنتين",
    nameEn: "Argentina (+54)",
    noCodeNameAr: "الأرجنتين",
    noCodeNameEn: "Argentina",
  },
  {
    isoNation: "AS",
    nation: "ASM",
    dialCode: "+1",
    nameAr: "(+1684) ساموا الأميريكية",
    nameEn: "American Samoa (+1684)",
    noCodeNameAr: "ساموا الأميريكية",
    noCodeNameEn: "American Samoa",
  },
  {
    isoNation: "AT",
    nation: "AUT",
    dialCode: "+43",
    nameAr: "(+43) النمسا",
    nameEn: "Austria (+43)",
    noCodeNameAr: "النمسا",
    noCodeNameEn: "Austria",
  },
  {
    isoNation: "AU",
    nation: "AUS",
    dialCode: "+61",
    nameAr: "(+61) أستراليا",
    nameEn: "Australia (+61)",
    noCodeNameAr: "أستراليا",
    noCodeNameEn: "Australia",
  },
  {
    isoNation: "AW",
    nation: "ABW",
    dialCode: "+297",
    nameAr: "(+297) أروبا",
    nameEn: "Aruba (+297)",
    noCodeNameAr: "أروبا",
    noCodeNameEn: "Aruba",
  },
  {
    isoNation: "AX",
    nation: "ALA",
    dialCode: "+358",
    nameAr: "(+358) جزر آلاند",
    nameEn: "Aland Islands (+358)",
    noCodeNameAr: "جزر آلاند",
    noCodeNameEn: "Aland Islands",
  },
  {
    isoNation: "AZ",
    nation: "AZE",
    dialCode: "+994",
    nameAr: "(+994) أزربيجان",
    nameEn: "Azerbaijan (+994)",
    noCodeNameAr: "أزربيجان",
    noCodeNameEn: "Azerbaijan",
  },
  {
    isoNation: "BA",
    nation: "BIH",
    dialCode: "+387",
    nameAr: "(+387) البوسنة والهرسك",
    nameEn: "Bosnia and Herzegovina (+387)",
    noCodeNameAr: "البوسنة والهرسك",
    noCodeNameEn: "Bosnia and Herzegovina",
  },
  {
    isoNation: "BB",
    nation: "BRB",
    dialCode: "+1",
    nameAr: "(+1246) باربادوس",
    nameEn: "Barbados (+1246)",
    noCodeNameAr: "باربادوس",
    noCodeNameEn: "Barbados",
  },
  {
    isoNation: "BD",
    nation: "BGD",
    dialCode: "+880",
    nameAr: "(+880) بنغلادش",
    nameEn: "Bangladesh (+880)",
    noCodeNameAr: "بنغلادش",
    noCodeNameEn: "Bangladesh",
  },
  {
    isoNation: "BE",
    nation: "BEL",
    dialCode: "+32",
    nameAr: "(+32) بلجيكا",
    nameEn: "Belgium (+32)",
    noCodeNameAr: "بلجيكا",
    noCodeNameEn: "Belgium",
  },
  {
    isoNation: "BF",
    nation: "BFA",
    dialCode: "+226",
    nameAr: "(+226) بوركينا فاسو",
    nameEn: "Burkina Faso (+226)",
    noCodeNameAr: "بوركينا فاسو",
    noCodeNameEn: "Burkina Faso",
  },
  {
    isoNation: "BG",
    nation: "BGR",
    dialCode: "+359",
    nameAr: "(+359) بلغاريا",
    nameEn: "Bulgaria (+359)",
    noCodeNameAr: "بلغاريا",
    noCodeNameEn: "Bulgaria",
  },
  {
    isoNation: "BH",
    nation: "BHR",
    dialCode: "+973",
    nameAr: "(+973) البحرين",
    nameEn: "Bahrain (+973)",
    noCodeNameAr: "البحرين",
    noCodeNameEn: "Bahrain",
  },
  {
    isoNation: "BI",
    nation: "BDI",
    dialCode: "+257",
    nameAr: "(+257) بوروندي",
    nameEn: "Burundi (+257)",
    noCodeNameAr: "بوروندي",
    noCodeNameEn: "Burundi",
  },
  {
    isoNation: "BJ",
    nation: "BEN",
    dialCode: "+229",
    nameAr: "(+229) بينين",
    nameEn: "Benin (+229)",
    noCodeNameAr: "بينين",
    noCodeNameEn: "Benin",
  },
  {
    isoNation: "BL",
    nation: "BLM",
    dialCode: "+590",
    nameAr: "(+590) سانت بارتيليمي",
    nameEn: "Saint Barthelemy (+590)",
    noCodeNameAr: "سانت بارتيليمي",
    noCodeNameEn: "Saint Barthelemy",
  },
  {
    isoNation: "BM",
    nation: "BMU",
    dialCode: "+1",
    nameAr: "(+1441) بيرمودا",
    nameEn: "Bermuda (+1441)",
    noCodeNameAr: "بيرمودا",
    noCodeNameEn: "Bermuda",
  },
  {
    isoNation: "BN",
    nation: "BRN",
    dialCode: "+673",
    nameAr: "(+673) بروناي",
    nameEn: "Brunei (+673)",
    noCodeNameAr: "بروناي",
    noCodeNameEn: "Brunei",
  },
  {
    isoNation: "BO",
    nation: "BOL",
    dialCode: "+591",
    nameAr: "(+591) بوليفيا",
    nameEn: "Bolivia (+591)",
    noCodeNameAr: "بوليفيا",
    noCodeNameEn: "Bolivia",
  },
  {
    isoNation: "BR",
    nation: "BRA",
    dialCode: "+55",
    nameAr: "(+55) البرازيل",
    nameEn: "Brazil (+55)",
    noCodeNameAr: "البرازيل",
    noCodeNameEn: "Brazil",
  },
  {
    isoNation: "BS",
    nation: "BHS",
    dialCode: "+1",
    nameAr: "(+1242) باهاماس",
    nameEn: "Bahamas (+1242)",
    noCodeNameAr: "باهاماس",
    noCodeNameEn: "Bahamas",
  },
  {
    isoNation: "BT",
    nation: "BTN",
    dialCode: "+975",
    nameAr: "(+975) بوتان",
    nameEn: "Bhutan (+975)",
    noCodeNameAr: "بوتان",
    noCodeNameEn: "Bhutan",
  },
  {
    isoNation: "BW",
    nation: "BWA",
    dialCode: "+267",
    nameAr: "(+267) بوتسوانا",
    nameEn: "Botswana (+267)",
    noCodeNameAr: "بوتسوانا",
    noCodeNameEn: "Botswana",
  },
  {
    isoNation: "BY",
    nation: "BLR",
    dialCode: "+375",
    nameAr: "(+375) بلاروسيا",
    nameEn: "Belarus (+375)",
    noCodeNameAr: "بلاروسيا",
    noCodeNameEn: "Belarus",
  },
  {
    isoNation: "BZ",
    nation: "BLZ",
    dialCode: "+501",
    nameAr: "(+501) بيليز",
    nameEn: "Belize (+501)",
    noCodeNameAr: "بيليز",
    noCodeNameEn: "Belize",
  },
  {
    isoNation: "CA",
    nation: "CAN",
    dialCode: "+1",
    nameAr: "(+1) كندا",
    nameEn: "Canada (+1)",
    noCodeNameAr: "كندا",
    noCodeNameEn: "Canada",
  },
  {
    isoNation: "CC",
    nation: "CCK",
    dialCode: "+61",
    nameAr: "(+61) جزر الكوكو",
    nameEn: "Cocos Islands (+61)",
    noCodeNameAr: "جزر الكوكو",
    noCodeNameEn: "Cocos Islands",
  },
  {
    isoNation: "CD",
    nation: "COD",
    dialCode: "+243",
    nameAr: "(+243) جمهورية الكونغو الديمقراطية",
    nameEn: "Democratic Republic of the Congo (+243)",
    noCodeNameAr: "جمهورية الكونغو الديمقراطية",
    noCodeNameEn: "Democratic Republic of the Congo",
  },
  {
    isoNation: "CF",
    nation: "CAF",
    dialCode: "+236",
    nameAr: "(+236) جمهورية أفريقيا الوسطى",
    nameEn: "Central African Republic (+236)",
    noCodeNameAr: "جمهورية أفريقيا الوسطى",
    noCodeNameEn: "Central African Republic",
  },
  {
    isoNation: "CG",
    nation: "COG",
    dialCode: "+242",
    nameAr: "(+242) الكونغو",
    nameEn: "Congo (+242)",
    noCodeNameAr: "الكونغو",
    noCodeNameEn: "Congo",
  },
  {
    isoNation: "CH",
    nation: "CHE",
    dialCode: "+41",
    nameAr: "(+41) سويسرا",
    nameEn: "Switzerland (+41)",
    noCodeNameAr: "سويسرا",
    noCodeNameEn: "Switzerland",
  },
  {
    isoNation: "CI",
    nation: "CIV",
    dialCode: "+225",
    nameAr: "(+225) ساحل العاج",
    nameEn: "Ivory Coast (+225)",
    noCodeNameAr: "ساحل العاج",
    noCodeNameEn: "Ivory Coast",
  },
  {
    isoNation: "CK",
    nation: "COK",
    dialCode: "+682",
    nameAr: "(+682) جزر كوك",
    nameEn: "Cook Islands (+682)",
    noCodeNameAr: "جزر كوك",
    noCodeNameEn: "Cook Islands",
  },
  {
    isoNation: "CL",
    nation: "CHL",
    dialCode: "+56",
    nameAr: "(+56) شيلي",
    nameEn: "Chile (+56)",
    noCodeNameAr: "شيلي",
    noCodeNameEn: "Chile",
  },
  {
    isoNation: "CM",
    nation: "CMR",
    dialCode: "+237",
    nameAr: "(+237) الكاميرون",
    nameEn: "Cameroon (+237)",
    noCodeNameAr: "الكاميرون",
    noCodeNameEn: "Cameroon",
  },
  {
    isoNation: "CN",
    nation: "CHN",
    dialCode: "+86",
    nameAr: "(+86) الصين",
    nameEn: "China (+86)",
    noCodeNameAr: "الصين",
    noCodeNameEn: "China",
  },
  {
    isoNation: "CO",
    nation: "COL",
    dialCode: "+57",
    nameAr: "(+57) كولومبيا",
    nameEn: "Colombia (+57)",
    noCodeNameAr: "كولومبيا",
    noCodeNameEn: "Colombia",
  },
  {
    isoNation: "CR",
    nation: "CRI",
    dialCode: "+506",
    nameAr: "(+506) كوستا ريكا",
    nameEn: "Costa Rica (+506)",
    noCodeNameAr: "كوستا ريكا",
    noCodeNameEn: "Costa Rica",
  },
  {
    isoNation: "CU",
    nation: "CUB",
    dialCode: "+53",
    nameAr: "(+53) كوبا",
    nameEn: "Cuba (+53)",
    noCodeNameAr: "كوبا",
    noCodeNameEn: "Cuba",
  },
  {
    isoNation: "CV",
    nation: "CPV",
    dialCode: "+238",
    nameAr: "(+238) الرأس الأخضر",
    nameEn: "Cape Verde (+238)",
    noCodeNameAr: "الرأس الأخضر",
    noCodeNameEn: "Cape Verde",
  },
  {
    isoNation: "CW",
    nation: "CUW",
    dialCode: "+599",
    nameAr: "(+599) كوراساو",
    nameEn: "Curacao (+599)",
    noCodeNameAr: "كوراساو",
    noCodeNameEn: "Curacao",
  },
  {
    isoNation: "CX",
    nation: "CXR",
    dialCode: "+61",
    nameAr: "(+61) جزيرة الميلاد",
    nameEn: "Christmas Island (+61)",
    noCodeNameAr: "جزيرة الميلاد",
    noCodeNameEn: "Christmas Island",
  },
  {
    isoNation: "CY",
    nation: "CYP",
    dialCode: "+357",
    nameAr: "(+357) قبرص",
    nameEn: "Cyprus (+357)",
    noCodeNameAr: "قبرص",
    noCodeNameEn: "Cyprus",
  },
  {
    isoNation: "CZ",
    nation: "CZE",
    dialCode: "+420",
    nameAr: "(+420) جمهورية التشيك",
    nameEn: "Czech Republic (+420)",
    noCodeNameAr: "جمهورية التشيك",
    noCodeNameEn: "Czech Republic",
  },
  {
    isoNation: "DE",
    nation: "DEU",
    dialCode: "+49",
    nameAr: "(+49) ألمانيا",
    nameEn: "Germany (+49)",
    noCodeNameAr: "ألمانيا",
    noCodeNameEn: "Germany",
  },
  {
    isoNation: "DJ",
    nation: "DJI",
    dialCode: "+253",
    nameAr: "(+253) جيبوتي",
    nameEn: "Djibouti (+253)",
    noCodeNameAr: "جيبوتي",
    noCodeNameEn: "Djibouti",
  },
  {
    isoNation: "DK",
    nation: "DNK",
    dialCode: "+45",
    nameAr: "(+45) الدانمارك",
    nameEn: "Denmark (+45)",
    noCodeNameAr: "الدانمارك",
    noCodeNameEn: "Denmark",
  },
  {
    isoNation: "DM",
    nation: "DMA",
    dialCode: "+1",
    nameAr: "(+1767) دومينيكا",
    nameEn: "Dominica (+1767)",
    noCodeNameAr: "دومينيكا",
    noCodeNameEn: "Dominica",
  },
  {
    isoNation: "DO",
    nation: "DOM",
    dialCode: "+1",
    nameAr: "(+1) جمهورية الدومينيكان",
    nameEn: "Dominican Republic (+1)",
    noCodeNameAr: "جمهورية الدومينيكان",
    noCodeNameEn: "Dominican Republic",
  },
  {
    isoNation: "DZ",
    nation: "DZA",
    dialCode: "+213",
    nameAr: "(+213) الجزائر",
    nameEn: "Algeria (+213)",
    noCodeNameAr: "الجزائر",
    noCodeNameEn: "Algeria",
  },
  {
    isoNation: "EC",
    nation: "ECU",
    dialCode: "+593",
    nameAr: "(+593) الإكوادور",
    nameEn: "Ecuador (+593)",
    noCodeNameAr: "الإكوادور",
    noCodeNameEn: "Ecuador",
  },
  {
    isoNation: "EE",
    nation: "EST",
    dialCode: "+372",
    nameAr: "(+372) إستونيا",
    nameEn: "Estonia (+372)",
    noCodeNameAr: "إستونيا",
    noCodeNameEn: "Estonia",
  },
  {
    isoNation: "EG",
    nation: "EGY",
    dialCode: "+20",
    nameAr: "(+20) مصر",
    nameEn: "Egypt (+20)",
    noCodeNameAr: "مصر",
    noCodeNameEn: "Egypt",
  },
  {
    isoNation: "ER",
    nation: "ERI",
    dialCode: "+291",
    nameAr: "(+291) إيريتريا",
    nameEn: "Eritrea (+291)",
    noCodeNameAr: "إيريتريا",
    noCodeNameEn: "Eritrea",
  },
  {
    isoNation: "ES",
    nation: "ESP",
    dialCode: "+34",
    nameAr: "(+34) اسبانيا",
    nameEn: "Spain (+34)",
    noCodeNameAr: "اسبانيا",
    noCodeNameEn: "Spain",
  },
  {
    isoNation: "ET",
    nation: "ETH",
    dialCode: "+251",
    nameAr: "(+251) إثيوبيا",
    nameEn: "Ethiopia (+251)",
    noCodeNameAr: "إثيوبيا",
    noCodeNameEn: "Ethiopia",
  },
  {
    isoNation: "FI",
    nation: "FIN",
    dialCode: "+358",
    nameAr: "(+358) فنلندا",
    nameEn: "Finland (+358)",
    noCodeNameAr: "فنلندا",
    noCodeNameEn: "Finland",
  },
  {
    isoNation: "FJ",
    nation: "FJI",
    dialCode: "+679",
    nameAr: "(+679) جزر فيجي",
    nameEn: "Fiji (+679)",
    noCodeNameAr: "جزر فيجي",
    noCodeNameEn: "Fiji",
  },
  {
    isoNation: "FK",
    nation: "FLK",
    dialCode: "+500",
    nameAr: "(+500) جزر فولكلاند",
    nameEn: "Falkland Islands (+500)",
    noCodeNameAr: "جزر فولكلاند",
    noCodeNameEn: "Falkland Islands",
  },
  {
    isoNation: "FM",
    nation: "FSM",
    dialCode: "+691",
    nameAr: "(+691) ميكرونيسيا",
    nameEn: "Micronesia (+691)",
    noCodeNameAr: "ميكرونيسيا",
    noCodeNameEn: "Micronesia",
  },
  {
    isoNation: "FO",
    nation: "FRO",
    dialCode: "+298",
    nameAr: "(+298) جزر الفارو",
    nameEn: "Faroe Islands (+298)",
    noCodeNameAr: "جزر الفارو",
    noCodeNameEn: "Faroe Islands",
  },
  {
    isoNation: "FR",
    nation: "FRA",
    dialCode: "+33",
    nameAr: "(+33) فرنسا",
    nameEn: "France (+33)",
    noCodeNameAr: "فرنسا",
    noCodeNameEn: "France",
  },
  {
    isoNation: "GA",
    nation: "GAB",
    dialCode: "+241",
    nameAr: "(+241) الغابون",
    nameEn: "Gabon (+241)",
    noCodeNameAr: "الغابون",
    noCodeNameEn: "Gabon",
  },
  {
    isoNation: "AE",
    nation: "UAE",
    dialCode: "+971",
    nameAr: "(+971) الإمارات العربية المتحدة",
    nameEn: "United Arab Emirates (+971)",
    noCodeNameAr: "الإمارات العربية المتحدة",
    noCodeNameEn: "United Arab Emirates",
  },
  {
    isoNation: "GB",
    nation: "GBR",
    dialCode: "+44",
    nameAr: "(+44) بريطانيا",
    nameEn: "United Kingdom (+44)",
    noCodeNameAr: "بريطانيا",
    noCodeNameEn: "United Kingdom",
  },
  {
    isoNation: "GD",
    nation: "GRD",
    dialCode: "+1",
    nameAr: "(+1473) غرينادا",
    nameEn: "Grenada (+1473)",
    noCodeNameAr: "غرينادا",
    noCodeNameEn: "Grenada",
  },
  {
    isoNation: "GE",
    nation: "GEO",
    dialCode: "+995",
    nameAr: "(+995) جورجيا",
    nameEn: "Georgia (+995)",
    noCodeNameAr: "جورجيا",
    noCodeNameEn: "Georgia",
  },
  {
    isoNation: "GF",
    nation: "GUF",
    dialCode: "+594",
    nameAr: "(+594) غويانا الفرنسية",
    nameEn: "French Guiana (+594)",
    noCodeNameAr: "غويانا الفرنسية",
    noCodeNameEn: "French Guiana",
  },
  {
    isoNation: "GG",
    nation: "GGY",
    dialCode: "+44",
    nameAr: "(+44) غيرنسي",
    nameEn: "Guernsey (+44)",
    noCodeNameAr: "غيرنسي",
    noCodeNameEn: "Guernsey",
  },
  {
    isoNation: "GH",
    nation: "GHA",
    dialCode: "+233",
    nameAr: "(+233) غانا",
    nameEn: "Ghana (+233)",
    noCodeNameAr: "غانا",
    noCodeNameEn: "Ghana",
  },
  {
    isoNation: "GI",
    nation: "GIB",
    dialCode: "+350",
    nameAr: "(+350) جبل طارق",
    nameEn: "Gibraltar (+350)",
    noCodeNameAr: "جبل طارق",
    noCodeNameEn: "Gibraltar",
  },
  {
    isoNation: "GL",
    nation: "GRL",
    dialCode: "+299",
    nameAr: "(+299) جرينلاند",
    nameEn: "Greenland (+299)",
    noCodeNameAr: "جرينلاند",
    noCodeNameEn: "Greenland",
  },
  {
    isoNation: "GM",
    nation: "GMB",
    dialCode: "+220",
    nameAr: "(+220) غامبيا",
    nameEn: "Gambia (+220)",
    noCodeNameAr: "غامبيا",
    noCodeNameEn: "Gambia",
  },
  {
    isoNation: "GN",
    nation: "GIN",
    dialCode: "+224",
    nameAr: "(+224) غينيا",
    nameEn: "Guinea (+224)",
    noCodeNameAr: "غينيا",
    noCodeNameEn: "Guinea",
  },
  {
    isoNation: "GP",
    nation: "GLP",
    dialCode: "+590",
    nameAr: "(+590) غوادلوب",
    nameEn: "Guadeloupe (+590)",
    noCodeNameAr: "غوادلوب",
    noCodeNameEn: "Guadeloupe",
  },
  {
    isoNation: "GQ",
    nation: "GNQ",
    dialCode: "+240",
    nameAr: "(+240) غينيا الإستوائية",
    nameEn: "Equatorial Guinea (+240)",
    noCodeNameAr: "غينيا الإستوائية",
    noCodeNameEn: "Equatorial Guinea",
  },
  {
    isoNation: "GR",
    nation: "GRC",
    dialCode: "+30",
    nameAr: "(+30) اليونان",
    nameEn: "Greece (+30)",
    noCodeNameAr: "اليونان",
    noCodeNameEn: "Greece",
  },
  {
    isoNation: "GS",
    nation: "SGS",
    dialCode: "+500",
    nameAr: "(+500) جزر جورجيا الجنوبية",
    nameEn: "South Georgia and the South Sandwich Islands (+500)",
    noCodeNameAr: "جزر جورجيا الجنوبية",
    noCodeNameEn: "South Georgia and the South Sandwich Islands",
  },
  {
    isoNation: "GT",
    nation: "GTM",
    dialCode: "+502",
    nameAr: "(+502) غواتيمالا",
    nameEn: "Guatemala (+502)",
    noCodeNameAr: "غواتيمالا",
    noCodeNameEn: "Guatemala",
  },
  {
    isoNation: "GU",
    nation: "GUM",
    dialCode: "+1",
    nameAr: "(+1671) غوام",
    nameEn: "Guam (+1671)",
    noCodeNameAr: "غوام",
    noCodeNameEn: "Guam",
  },
  {
    isoNation: "GW",
    nation: "GNB",
    dialCode: "+245",
    nameAr: "(+245) غينيا بيساو",
    nameEn: "Guinea-Bissau (+245)",
    noCodeNameAr: "غينيا بيساو",
    noCodeNameEn: "Guinea-Bissau",
  },
  {
    isoNation: "GY",
    nation: "GUY",
    dialCode: "+592",
    nameAr: "(+592) غيانا",
    nameEn: "Guyana (+592)",
    noCodeNameAr: "غيانا",
    noCodeNameEn: "Guyana",
  },
  {
    isoNation: "HK",
    nation: "HKG",
    dialCode: "+852",
    nameAr: "(+852) هونغ كونغ (الصين)",
    nameEn: "Hong Kong (China) (+852)",
    noCodeNameAr: "هونغ كونغ (الصين)",
    noCodeNameEn: "Hong Kong (China)",
  },
  {
    isoNation: "HN",
    nation: "HND",
    dialCode: "+504",
    nameAr: "(+504) هوندوراس",
    nameEn: "Honduras (+504)",
    noCodeNameAr: "هوندوراس",
    noCodeNameEn: "Honduras",
  },
  {
    isoNation: "HR",
    nation: "HRV",
    dialCode: "+385",
    nameAr: "(+385) كرواتيا",
    nameEn: "Croatia (+385)",
    noCodeNameAr: "كرواتيا",
    noCodeNameEn: "Croatia",
  },
  {
    isoNation: "HT",
    nation: "HTI",
    dialCode: "+509",
    nameAr: "(+509) هايتي",
    nameEn: "Haiti (+509)",
    noCodeNameAr: "هايتي",
    noCodeNameEn: "Haiti",
  },
  {
    isoNation: "HU",
    nation: "HUN",
    dialCode: "+36",
    nameAr: "(+36) المجر",
    nameEn: "Hungary (+36)",
    noCodeNameAr: "المجر",
    noCodeNameEn: "Hungary",
  },
  {
    isoNation: "ID",
    nation: "IDN",
    dialCode: "+62",
    nameAr: "(+62) إندونيسيا",
    nameEn: "Indonesia (+62)",
    noCodeNameAr: "إندونيسيا",
    noCodeNameEn: "Indonesia",
  },
  {
    isoNation: "IE",
    nation: "IRL",
    dialCode: "+353",
    nameAr: "(+353) إيرلندا",
    nameEn: "Ireland (+353)",
    noCodeNameAr: "إيرلندا",
    noCodeNameEn: "Ireland",
  },
  {
    isoNation: "IL",
    nation: "ISR",
    dialCode: "+972",
    nameAr: "(+972) إسرائيل",
    nameEn: "Israel (+972)",
    noCodeNameAr: "إسرائيل",
    noCodeNameEn: "Israel",
  },
  {
    isoNation: "IM",
    nation: "IMN",
    dialCode: "+44",
    nameAr: "(+44) جزيرة مان",
    nameEn: "Isle of Man (+44)",
    noCodeNameAr: "جزيرة مان",
    noCodeNameEn: "Isle of Man",
  },
  {
    isoNation: "IN",
    nation: "IND",
    dialCode: "+91",
    nameAr: "(+91) الهند",
    nameEn: "India (+91)",
    noCodeNameAr: "الهند",
    noCodeNameEn: "India",
  },
  {
    isoNation: "IO",
    nation: "IOT",
    dialCode: "+246",
    nameAr: "(+246) إقليم المحيط البريطاني الهندي",
    nameEn: "British Indian Ocean Territory (+246)",
    noCodeNameAr: "إقليم المحيط البريطاني الهندي",
    noCodeNameEn: "British Indian Ocean Territory",
  },
  {
    isoNation: "IQ",
    nation: "IRQ",
    dialCode: "+964",
    nameAr: "(+964) العراق",
    nameEn: "Iraq (+964)",
    noCodeNameAr: "العراق",
    noCodeNameEn: "Iraq",
  },
  {
    isoNation: "IR",
    nation: "IRN",
    dialCode: "+98",
    nameAr: "(+98) إيران",
    nameEn: "Iran (+98)",
    noCodeNameAr: "إيران",
    noCodeNameEn: "Iran",
  },
  {
    isoNation: "IS",
    nation: "ISL",
    dialCode: "+354",
    nameAr: "(+354) إيسلندا",
    nameEn: "Iceland (+354)",
    noCodeNameAr: "إيسلندا",
    noCodeNameEn: "Iceland",
  },
  {
    isoNation: "IT",
    nation: "ITA",
    dialCode: "+39",
    nameAr: "(+39) إيطاليا",
    nameEn: "Italy (+39)",
    noCodeNameAr: "إيطاليا",
    noCodeNameEn: "Italy",
  },
  {
    isoNation: "JE",
    nation: "JEY",
    dialCode: "+44",
    nameAr: "(+44) جيرسي",
    nameEn: "Jersey (+44)",
    noCodeNameAr: "جيرسي",
    noCodeNameEn: "Jersey",
  },
  {
    isoNation: "JM",
    nation: "JAM",
    dialCode: "+1",
    nameAr: "(+1876) جامايكا",
    nameEn: "Jamaica (+1876)",
    noCodeNameAr: "جامايكا",
    noCodeNameEn: "Jamaica",
  },
  {
    isoNation: "JO",
    nation: "JOR",
    dialCode: "+962",
    nameAr: "(+962) الأردن",
    nameEn: "Jordan (+962)",
    noCodeNameAr: "الأردن",
    noCodeNameEn: "Jordan",
  },
  {
    isoNation: "JP",
    nation: "JPN",
    dialCode: "+81",
    nameAr: "(+81) اليابان",
    nameEn: "Japan (+81)",
    noCodeNameAr: "اليابان",
    noCodeNameEn: "Japan",
  },
  {
    isoNation: "KE",
    nation: "KEN",
    dialCode: "+254",
    nameAr: "(+254) كينيا",
    nameEn: "Kenya (+254)",
    noCodeNameAr: "كينيا",
    noCodeNameEn: "Kenya",
  },
  {
    isoNation: "KG",
    nation: "KGZ",
    dialCode: "+996",
    nameAr: "(+996) قيرغيزستان",
    nameEn: "Kyrgyzstan (+996)",
    noCodeNameAr: "قيرغيزستان",
    noCodeNameEn: "Kyrgyzstan",
  },
  {
    isoNation: "KH",
    nation: "KHM",
    dialCode: "+855",
    nameAr: "(+855) كامبوديا",
    nameEn: "Cambodia (+855)",
    noCodeNameAr: "كامبوديا",
    noCodeNameEn: "Cambodia",
  },
  {
    isoNation: "KI",
    nation: "KIR",
    dialCode: "+686",
    nameAr: "(+686) كيريباتي",
    nameEn: "Kiribati (+686)",
    noCodeNameAr: "كيريباتي",
    noCodeNameEn: "Kiribati",
  },
  {
    isoNation: "KM",
    nation: "COM",
    dialCode: "+269",
    nameAr: "(+269) جزر القمر",
    nameEn: "Comoros (+269)",
    noCodeNameAr: "جزر القمر",
    noCodeNameEn: "Comoros",
  },
  {
    isoNation: "KN",
    nation: "KNA",
    dialCode: "+1",
    nameAr: "(+1869) سانت كيتس أند نيفيس",
    nameEn: "Saint Kitts and Nevis (+1869)",
    noCodeNameAr: "سانت كيتس أند نيفيس",
    noCodeNameEn: "Saint Kitts and Nevis",
  },
  {
    isoNation: "KP",
    nation: "PRK",
    dialCode: "+850",
    nameAr: "(+850) كوريا الشمالية",
    nameEn: "North Korea (+850)",
    noCodeNameAr: "كوريا الشمالية",
    noCodeNameEn: "North Korea",
  },
  {
    isoNation: "KR",
    nation: "KOR",
    dialCode: "+82",
    nameAr: "(+82) كوريا الجنوبية",
    nameEn: "South Korea (+82)",
    noCodeNameAr: "كوريا الجنوبية",
    noCodeNameEn: "South Korea",
  },
  {
    isoNation: "KW",
    nation: "KWT",
    dialCode: "+965",
    nameAr: "(+965) الكويت",
    nameEn: "Kuwait (+965)",
    noCodeNameAr: "الكويت",
    noCodeNameEn: "Kuwait",
  },
  {
    isoNation: "KY",
    nation: "CYM",
    dialCode: "+1",
    nameAr: "(+1345) جزر كايمن",
    nameEn: "Cayman Islands (+1345)",
    noCodeNameAr: "جزر كايمن",
    noCodeNameEn: "Cayman Islands",
  },
  {
    isoNation: "KZ",
    nation: "KAZ",
    dialCode: "+7",
    nameAr: "(+7) كازاخستان",
    nameEn: "Kazakhstan (+7)",
    noCodeNameAr: "كازاخستان",
    noCodeNameEn: "Kazakhstan",
  },
  {
    isoNation: "LA",
    nation: "LAO",
    dialCode: "+856",
    nameAr: "(+856) لاوس",
    nameEn: "Laos (+856)",
    noCodeNameAr: "لاوس",
    noCodeNameEn: "Laos",
  },
  {
    isoNation: "LB",
    nation: "LBN",
    dialCode: "+961",
    nameAr: "(+961) لبنان",
    nameEn: "Lebanon (+961)",
    noCodeNameAr: "لبنان",
    noCodeNameEn: "Lebanon",
  },
  {
    isoNation: "LC",
    nation: "LCA",
    dialCode: "+1",
    nameAr: "(+1758) سانت لوسيا",
    nameEn: "Saint Lucia (+1758)",
    noCodeNameAr: "سانت لوسيا",
    noCodeNameEn: "Saint Lucia",
  },
  {
    isoNation: "LI",
    nation: "LIE",
    dialCode: "+423",
    nameAr: "(+423) ليشنيشتاين",
    nameEn: "Liechtenstein (+423)",
    noCodeNameAr: "ليشنيشتاين",
    noCodeNameEn: "Liechtenstein",
  },
  {
    isoNation: "LK",
    nation: "LKA",
    dialCode: "+94",
    nameAr: "(+94) سيريلانكا",
    nameEn: "Sri Lanka (+94)",
    noCodeNameAr: "سيريلانكا",
    noCodeNameEn: "Sri Lanka",
  },
  {
    isoNation: "LR",
    nation: "LBR",
    dialCode: "+231",
    nameAr: "(+231) ليبيريا",
    nameEn: "Liberia (+231)",
    noCodeNameAr: "ليبيريا",
    noCodeNameEn: "Liberia",
  },
  {
    isoNation: "LS",
    nation: "LSO",
    dialCode: "+266",
    nameAr: "(+266) ليزوتو",
    nameEn: "Lesotho (+266)",
    noCodeNameAr: "ليزوتو",
    noCodeNameEn: "Lesotho",
  },
  {
    isoNation: "LT",
    nation: "LTU",
    dialCode: "+370",
    nameAr: "(+370) ليتوانيا",
    nameEn: "Lithuania (+370)",
    noCodeNameAr: "ليتوانيا",
    noCodeNameEn: "Lithuania",
  },
  {
    isoNation: "LU",
    nation: "LUX",
    dialCode: "+352",
    nameAr: "(+352) لوكسمبورغ",
    nameEn: "Luxembourg (+352)",
    noCodeNameAr: "لوكسمبورغ",
    noCodeNameEn: "Luxembourg",
  },
  {
    isoNation: "LV",
    nation: "LVA",
    dialCode: "+371",
    nameAr: "(+371) لاتفيا",
    nameEn: "Latvia (+371)",
    noCodeNameAr: "لاتفيا",
    noCodeNameEn: "Latvia",
  },
  {
    isoNation: "LY",
    nation: "LBY",
    dialCode: "+218",
    nameAr: "(+218) ليبيا",
    nameEn: "Libya (+218)",
    noCodeNameAr: "ليبيا",
    noCodeNameEn: "Libya",
  },
  {
    isoNation: "MA",
    nation: "MAR",
    dialCode: "+212",
    nameAr: "(+212) المغرب",
    nameEn: "Morocco (+212)",
    noCodeNameAr: "المغرب",
    noCodeNameEn: "Morocco",
  },
  {
    isoNation: "MC",
    nation: "MCO",
    dialCode: "+377",
    nameAr: "(+377) موناكو",
    nameEn: "Monaco (+377)",
    noCodeNameAr: "موناكو",
    noCodeNameEn: "Monaco",
  },
  {
    isoNation: "MD",
    nation: "MDA",
    dialCode: "+373",
    nameAr: "(+373) مولدوفا",
    nameEn: "Moldova (+373)",
    noCodeNameAr: "مولدوفا",
    noCodeNameEn: "Moldova",
  },
  {
    isoNation: "ME",
    nation: "MNE",
    dialCode: "+382",
    nameAr: "(+382) الجبل الأسود",
    nameEn: "Montenegro (+382)",
    noCodeNameAr: "الجبل الأسود",
    noCodeNameEn: "Montenegro",
  },
  {
    isoNation: "MF",
    nation: "MAF",
    dialCode: "+590",
    nameAr: "(+590) سانت مارتن",
    nameEn: "Saint Martin (+590)",
    noCodeNameAr: "سانت مارتن",
    noCodeNameEn: "Saint Martin",
  },
  {
    isoNation: "MG",
    nation: "MDG",
    dialCode: "+261",
    nameAr: "(+261) مدغشقر",
    nameEn: "Madagascar (+261)",
    noCodeNameAr: "مدغشقر",
    noCodeNameEn: "Madagascar",
  },
  {
    isoNation: "MH",
    nation: "MHL",
    dialCode: "+692",
    nameAr: "(+692) جزر مارشال",
    nameEn: "Marshall Islands (+692)",
    noCodeNameAr: "جزر مارشال",
    noCodeNameEn: "Marshall Islands",
  },
  {
    isoNation: "MK",
    nation: "MKD",
    dialCode: "+389",
    nameAr: "(+389) مقدونيا",
    nameEn: "Macedonia (+389)",
    noCodeNameAr: "مقدونيا",
    noCodeNameEn: "Macedonia",
  },
  {
    isoNation: "ML",
    nation: "MLI",
    dialCode: "+223",
    nameAr: "(+223) مالي",
    nameEn: "Mali (+223)",
    noCodeNameAr: "مالي",
    noCodeNameEn: "Mali",
  },
  {
    isoNation: "MM",
    nation: "MMR",
    dialCode: "+95",
    nameAr: "(+95) ميانمار",
    nameEn: "Myanmar (+95)",
    noCodeNameAr: "ميانمار",
    noCodeNameEn: "Myanmar",
  },
  {
    isoNation: "MN",
    nation: "MNG",
    dialCode: "+976",
    nameAr: "(+976) منغوليا",
    nameEn: "Mongolia (+976)",
    noCodeNameAr: "منغوليا",
    noCodeNameEn: "Mongolia",
  },
  {
    isoNation: "MO",
    nation: "MAC",
    dialCode: "+853",
    nameAr: "(+853) ماكاو (الصين)",
    nameEn: "Macao (China) (+853)",
    noCodeNameAr: "ماكاو (الصين)",
    noCodeNameEn: "Macao (China)",
  },
  {
    isoNation: "MP",
    nation: "MNP",
    dialCode: "+1",
    nameAr: "(+1670) جزر مريانا الشمالية",
    nameEn: "Northern Mariana Islands (+1670)",
    noCodeNameAr: "جزر مريانا الشمالية",
    noCodeNameEn: "Northern Mariana Islands",
  },
  {
    isoNation: "MQ",
    nation: "MTQ",
    dialCode: "+596",
    nameAr: "(+596) مارتينيك",
    nameEn: "Martinique (+596)",
    noCodeNameAr: "مارتينيك",
    noCodeNameEn: "Martinique",
  },
  {
    isoNation: "MR",
    nation: "MRT",
    dialCode: "+222",
    nameAr: "(+222) موريتانيا",
    nameEn: "Mauritania (+222)",
    noCodeNameAr: "موريتانيا",
    noCodeNameEn: "Mauritania",
  },
  {
    isoNation: "MS",
    nation: "MSR",
    dialCode: "+1",
    nameAr: "(+1664) مونتسرات",
    nameEn: "Montserrat (+1664)",
    noCodeNameAr: "مونتسرات",
    noCodeNameEn: "Montserrat",
  },
  {
    isoNation: "MT",
    nation: "MLT",
    dialCode: "+356",
    nameAr: "(+356) مالطا",
    nameEn: "Malta (+356)",
    noCodeNameAr: "مالطا",
    noCodeNameEn: "Malta",
  },
  {
    isoNation: "MU",
    nation: "MUS",
    dialCode: "+230",
    nameAr: "(+230) موريشيوس",
    nameEn: "Mauritius (+230)",
    noCodeNameAr: "موريشيوس",
    noCodeNameEn: "Mauritius",
  },
  {
    isoNation: "MV",
    nation: "MDV",
    dialCode: "+960",
    nameAr: "(+960) جزر المالديف",
    nameEn: "Maldives (+960)",
    noCodeNameAr: "جزر المالديف",
    noCodeNameEn: "Maldives",
  },
  {
    isoNation: "MW",
    nation: "MWI",
    dialCode: "+265",
    nameAr: "(+265) مالاوي",
    nameEn: "Malawi (+265)",
    noCodeNameAr: "مالاوي",
    noCodeNameEn: "Malawi",
  },
  {
    isoNation: "MX",
    nation: "MEX",
    dialCode: "+52",
    nameAr: "(+52) المكسيك",
    nameEn: "Mexico (+52)",
    noCodeNameAr: "المكسيك",
    noCodeNameEn: "Mexico",
  },
  {
    isoNation: "MY",
    nation: "MYS",
    dialCode: "+60",
    nameAr: "(+60) ماليزيا",
    nameEn: "Malaysia (+60)",
    noCodeNameAr: "ماليزيا",
    noCodeNameEn: "Malaysia",
  },
  {
    isoNation: "MZ",
    nation: "MOZ",
    dialCode: "+258",
    nameAr: "(+258) موزمبيق",
    nameEn: "Mozambique (+258)",
    noCodeNameAr: "موزمبيق",
    noCodeNameEn: "Mozambique",
  },
  {
    isoNation: "NA",
    nation: "NAM",
    dialCode: "+264",
    nameAr: "(+264) ناميبيا",
    nameEn: "Namibia (+264)",
    noCodeNameAr: "ناميبيا",
    noCodeNameEn: "Namibia",
  },
  {
    isoNation: "NC",
    nation: "NCL",
    dialCode: "+687",
    nameAr: "(+687) كاليدونيا الجديدة",
    nameEn: "New Caledonia (+687)",
    noCodeNameAr: "كاليدونيا الجديدة",
    noCodeNameEn: "New Caledonia",
  },
  {
    isoNation: "NE",
    nation: "NER",
    dialCode: "+227",
    nameAr: "(+227) النيجر",
    nameEn: "Niger (+227)",
    noCodeNameAr: "النيجر",
    noCodeNameEn: "Niger",
  },
  {
    isoNation: "NF",
    nation: "NFK",
    dialCode: "+672",
    nameAr: "(+672) جزيرة نورفولك",
    nameEn: "Norfolk Island (+672)",
    noCodeNameAr: "جزيرة نورفولك",
    noCodeNameEn: "Norfolk Island",
  },
  {
    isoNation: "NG",
    nation: "NGA",
    dialCode: "+234",
    nameAr: "(+234) نيجيريا",
    nameEn: "Nigeria (+234)",
    noCodeNameAr: "نيجيريا",
    noCodeNameEn: "Nigeria",
  },
  {
    isoNation: "NI",
    nation: "NIC",
    dialCode: "+505",
    nameAr: "(+505) نيكاراغوا",
    nameEn: "Nicaragua (+505)",
    noCodeNameAr: "نيكاراغوا",
    noCodeNameEn: "Nicaragua",
  },
  {
    isoNation: "NL",
    nation: "NLD",
    dialCode: "+31",
    nameAr: "(+31) هولندا",
    nameEn: "Netherlands (+31)",
    noCodeNameAr: "هولندا",
    noCodeNameEn: "Netherlands",
  },
  {
    isoNation: "NO",
    nation: "NOR",
    dialCode: "+47",
    nameAr: "(+47) النرويج",
    nameEn: "Norway (+47)",
    noCodeNameAr: "النرويج",
    noCodeNameEn: "Norway",
  },
  {
    isoNation: "NP",
    nation: "NPL",
    dialCode: "+977",
    nameAr: "(+977) نيبال",
    nameEn: "Nepal (+977)",
    noCodeNameAr: "نيبال",
    noCodeNameEn: "Nepal",
  },
  {
    isoNation: "NR",
    nation: "NRU",
    dialCode: "+674",
    nameAr: "(+674) ناورو",
    nameEn: "Nauru (+674)",
    noCodeNameAr: "ناورو",
    noCodeNameEn: "Nauru",
  },
  {
    isoNation: "NU",
    nation: "NIU",
    dialCode: "+683",
    nameAr: "(+683) نيو",
    nameEn: "Niue (+683)",
    noCodeNameAr: "نيو",
    noCodeNameEn: "Niue",
  },
  {
    isoNation: "NZ",
    nation: "NZL",
    dialCode: "+64",
    nameAr: "(+64) نيوزيلندا",
    nameEn: "New Zealand (+64)",
    noCodeNameAr: "نيوزيلندا",
    noCodeNameEn: "New Zealand",
  },
  {
    isoNation: "OM",
    nation: "OMN",
    dialCode: "+968",
    nameAr: "(+968) عمان",
    nameEn: "Oman (+968)",
    noCodeNameAr: "عمان",
    noCodeNameEn: "Oman",
  },
  {
    isoNation: "PA",
    nation: "PAN",
    dialCode: "+507",
    nameAr: "(+507) بنما",
    nameEn: "Panama (+507)",
    noCodeNameAr: "بنما",
    noCodeNameEn: "Panama",
  },
  {
    isoNation: "PE",
    nation: "PER",
    dialCode: "+51",
    nameAr: "(+51) بيرو",
    nameEn: "Peru (+51)",
    noCodeNameAr: "بيرو",
    noCodeNameEn: "Peru",
  },
  {
    isoNation: "PF",
    nation: "PYF",
    dialCode: "+689",
    nameAr: "(+689) بولينيزيا الفرنسية",
    nameEn: "French Polynesia (+689)",
    noCodeNameAr: "بولينيزيا الفرنسية",
    noCodeNameEn: "French Polynesia",
  },
  {
    isoNation: "PG",
    nation: "PNG",
    dialCode: "+675",
    nameAr: "(+675) بابوا غينيا الجديدة",
    nameEn: "Papua New Guinea (+675)",
    noCodeNameAr: "بابوا غينيا الجديدة",
    noCodeNameEn: "Papua New Guinea",
  },
  {
    isoNation: "PH",
    nation: "PHL",
    dialCode: "+63",
    nameAr: "(+63) الفلبين",
    nameEn: "Philippines (+63)",
    noCodeNameAr: "الفلبين",
    noCodeNameEn: "Philippines",
  },
  {
    isoNation: "PK",
    nation: "PAK",
    dialCode: "+92",
    nameAr: "(+92) باكستان",
    nameEn: "Pakistan (+92)",
    noCodeNameAr: "باكستان",
    noCodeNameEn: "Pakistan",
  },
  {
    isoNation: "PL",
    nation: "POL",
    dialCode: "+48",
    nameAr: "(+48) بولندا",
    nameEn: "Poland (+48)",
    noCodeNameAr: "بولندا",
    noCodeNameEn: "Poland",
  },
  {
    isoNation: "PM",
    nation: "SPM",
    dialCode: "+508",
    nameAr: "(+508) سانت بيير وميكلون",
    nameEn: "Saint Pierre and Miquelon (+508)",
    noCodeNameAr: "سانت بيير وميكلون",
    noCodeNameEn: "Saint Pierre and Miquelon",
  },
  {
    isoNation: "PN",
    nation: "PCN",
    dialCode: "+64",
    nameAr: "(+64) جزر بيتكيرن",
    nameEn: "Pitcairn (+64)",
    noCodeNameAr: "جزر بيتكيرن",
    noCodeNameEn: "Pitcairn",
  },
  {
    isoNation: "PR",
    nation: "PRI",
    dialCode: "+1",
    nameAr: "(+1) بورتوريكو",
    nameEn: "Puerto Rico (+1)",
    noCodeNameAr: "بورتوريكو",
    noCodeNameEn: "Puerto Rico",
  },
  {
    isoNation: "PS",
    nation: "PSE",
    dialCode: "+970",
    nameAr: "(+970) فلسطين",
    nameEn: "Palestine (+970)",
    noCodeNameAr: "فلسطين",
    noCodeNameEn: "Palestine",
  },
  {
    isoNation: "PT",
    nation: "PRT",
    dialCode: "+351",
    nameAr: "(+351) البرتغال",
    nameEn: "Portugal (+351)",
    noCodeNameAr: "البرتغال",
    noCodeNameEn: "Portugal",
  },
  {
    isoNation: "PW",
    nation: "PLW",
    dialCode: "+680",
    nameAr: "(+680) بالو",
    nameEn: "Palau (+680)",
    noCodeNameAr: "بالو",
    noCodeNameEn: "Palau",
  },
  {
    isoNation: "PY",
    nation: "PRY",
    dialCode: "+595",
    nameAr: "(+595) باراغواي",
    nameEn: "Paraguay (+595)",
    noCodeNameAr: "باراغواي",
    noCodeNameEn: "Paraguay",
  },
  {
    isoNation: "QA",
    nation: "QAT",
    dialCode: "+974",
    nameAr: "(+974) قطر",
    nameEn: "Qatar (+974)",
    noCodeNameAr: "قطر",
    noCodeNameEn: "Qatar",
  },
  {
    isoNation: "RE",
    nation: "REU",
    dialCode: "+262",
    nameAr: "(+262) ريونيون",
    nameEn: "Reunion (+262)",
    noCodeNameAr: "ريونيون",
    noCodeNameEn: "Reunion",
  },
  {
    isoNation: "RO",
    nation: "ROU",
    dialCode: "+40",
    nameAr: "(+40) رومانيا",
    nameEn: "Romania (+40)",
    noCodeNameAr: "رومانيا",
    noCodeNameEn: "Romania",
  },
  {
    isoNation: "RS",
    nation: "SRB",
    dialCode: "+381",
    nameAr: "(+381) صربيا",
    nameEn: "Serbia (+381)",
    noCodeNameAr: "صربيا",
    noCodeNameEn: "Serbia",
  },
  {
    isoNation: "RU",
    nation: "RUS",
    dialCode: "+7",
    nameAr: "(+7) روسيا",
    nameEn: "Russia (+7)",
    noCodeNameAr: "روسيا",
    noCodeNameEn: "Russia",
  },
  {
    isoNation: "RW",
    nation: "RWA",
    dialCode: "+250",
    nameAr: "(+250) رواندا",
    nameEn: "Rwanda (+250)",
    noCodeNameAr: "رواندا",
    noCodeNameEn: "Rwanda",
  },
  {
    isoNation: "SA",
    nation: "SAU",
    dialCode: "+966",
    nameAr: "(+966) السعودية",
    nameEn: "Saudi Arabia (+966)",
    noCodeNameAr: "السعودية",
    noCodeNameEn: "Saudi Arabia",
  },
  {
    isoNation: "SB",
    nation: "SLB",
    dialCode: "+677",
    nameAr: "(+677) جزر سليمان",
    nameEn: "Solomon Islands (+677)",
    noCodeNameAr: "جزر سليمان",
    noCodeNameEn: "Solomon Islands",
  },
  {
    isoNation: "SC",
    nation: "SYC",
    dialCode: "+248",
    nameAr: "(+248) سيشيل",
    nameEn: "Seychelles (+248)",
    noCodeNameAr: "سيشيل",
    noCodeNameEn: "Seychelles",
  },
  {
    isoNation: "SD",
    nation: "SDN",
    dialCode: "+249",
    nameAr: "(+249) السودان",
    nameEn: "Sudan (+249)",
    noCodeNameAr: "السودان",
    noCodeNameEn: "Sudan",
  },
  {
    isoNation: "SE",
    nation: "SWE",
    dialCode: "+46",
    nameAr: "(+46) السويد",
    nameEn: "Sweden (+46)",
    noCodeNameAr: "السويد",
    noCodeNameEn: "Sweden",
  },
  {
    isoNation: "SG",
    nation: "SGP",
    dialCode: "+65",
    nameAr: "(+65) سنغافورة",
    nameEn: "Singapore (+65)",
    noCodeNameAr: "سنغافورة",
    noCodeNameEn: "Singapore",
  },
  {
    isoNation: "SH",
    nation: "SHN",
    dialCode: "+290",
    nameAr: "(+290) سانت هيلينا",
    nameEn: "Saint Helena (+290)",
    noCodeNameAr: "سانت هيلينا",
    noCodeNameEn: "Saint Helena",
  },
  {
    isoNation: "SI",
    nation: "SVN",
    dialCode: "+386",
    nameAr: "(+386) سلوفينيا",
    nameEn: "Slovenia (+386)",
    noCodeNameAr: "سلوفينيا",
    noCodeNameEn: "Slovenia",
  },
  {
    isoNation: "SJ",
    nation: "SJM",
    dialCode: "+47",
    nameAr: "(+47) جزر سفالبارد و جان",
    nameEn: "Svalbard and Jan Mayen (+47)",
    noCodeNameAr: "جزر سفالبارد و جان",
    noCodeNameEn: "Svalbard and Jan Mayen",
  },
  {
    isoNation: "SK",
    nation: "SVK",
    dialCode: "+421",
    nameAr: "(+421) سلوفاكيا",
    nameEn: "Slovakia (+421)",
    noCodeNameAr: "سلوفاكيا",
    noCodeNameEn: "Slovakia",
  },
  {
    isoNation: "SL",
    nation: "SLE",
    dialCode: "+232",
    nameAr: "(+232) سييراليون",
    nameEn: "Sierra Leone (+232)",
    noCodeNameAr: "سييراليون",
    noCodeNameEn: "Sierra Leone",
  },
  {
    isoNation: "SM",
    nation: "SMR",
    dialCode: "+378",
    nameAr: "(+378) سان مارينو",
    nameEn: "San Marino (+378)",
    noCodeNameAr: "سان مارينو",
    noCodeNameEn: "San Marino",
  },
  {
    isoNation: "SN",
    nation: "SEN",
    dialCode: "+221",
    nameAr: "(+221) السنغال",
    nameEn: "Senegal (+221)",
    noCodeNameAr: "السنغال",
    noCodeNameEn: "Senegal",
  },
  {
    isoNation: "SO",
    nation: "SOM",
    dialCode: "+252",
    nameAr: "(+252) الصومال",
    nameEn: "Somalia (+252)",
    noCodeNameAr: "الصومال",
    noCodeNameEn: "Somalia",
  },
  {
    isoNation: "SR",
    nation: "SUR",
    dialCode: "+597",
    nameAr: "(+597) سورينام",
    nameEn: "Suriname (+597)",
    noCodeNameAr: "سورينام",
    noCodeNameEn: "Suriname",
  },
  {
    isoNation: "ST",
    nation: "STP",
    dialCode: "+239",
    nameAr: "(+239) ساو تومي أند برنسيب",
    nameEn: "Sao Tome and Principe (+239)",
    noCodeNameAr: "ساو تومي أند برنسيب",
    noCodeNameEn: "Sao Tome and Principe",
  },
  {
    isoNation: "SV",
    nation: "SLV",
    dialCode: "+503",
    nameAr: "(+503) السلفادور",
    nameEn: "El Salvador (+503)",
    noCodeNameAr: "السلفادور",
    noCodeNameEn: "El Salvador",
  },
  {
    isoNation: "SY",
    nation: "SYR",
    dialCode: "+963",
    nameAr: "(+963) سوريا",
    nameEn: "Syria (+963)",
    noCodeNameAr: "سوريا",
    noCodeNameEn: "Syria",
  },
  {
    isoNation: "SZ",
    nation: "SWZ",
    dialCode: "+268",
    nameAr: "(+268) سويسرا",
    nameEn: "Swaziland (+268)",
    noCodeNameAr: "سويسرا",
    noCodeNameEn: "Swaziland",
  },
  {
    isoNation: "TC",
    nation: "TCA",
    dialCode: "+1",
    nameAr: "(+1649) جزر الترك والقوقاز",
    nameEn: "Turks and Caicos Islands (+1649)",
    noCodeNameAr: "جزر الترك والقوقاز",
    noCodeNameEn: "Turks and Caicos Islands",
  },
  {
    isoNation: "TD",
    nation: "TCD",
    dialCode: "+235",
    nameAr: "(+235) تشاد",
    nameEn: "Chad (+235)",
    noCodeNameAr: "تشاد",
    noCodeNameEn: "Chad",
  },
  {
    isoNation: "TG",
    nation: "TGO",
    dialCode: "+228",
    nameAr: "(+228) توغو",
    nameEn: "Togo (+228)",
    noCodeNameAr: "توغو",
    noCodeNameEn: "Togo",
  },
  {
    isoNation: "TH",
    nation: "THA",
    dialCode: "+66",
    nameAr: "(+66) تايلاند",
    nameEn: "Thailand (+66)",
    noCodeNameAr: "تايلاند",
    noCodeNameEn: "Thailand",
  },
  {
    isoNation: "TJ",
    nation: "TJK",
    dialCode: "+992",
    nameAr: "(+992) طاجيكستان",
    nameEn: "Tajikistan (+992)",
    noCodeNameAr: "طاجيكستان",
    noCodeNameEn: "Tajikistan",
  },
  {
    isoNation: "TK",
    nation: "TKL",
    dialCode: "+690",
    nameAr: "(+690) توكيلاو",
    nameEn: "Tokelau (+690)",
    noCodeNameAr: "توكيلاو",
    noCodeNameEn: "Tokelau",
  },
  {
    isoNation: "TL",
    nation: "TLS",
    dialCode: "+670",
    nameAr: "(+670) تيمور الشرقية",
    nameEn: "Timor-Leste (+670)",
    noCodeNameAr: "تيمور الشرقية",
    noCodeNameEn: "Timor-Leste",
  },
  {
    isoNation: "TM",
    nation: "TKM",
    dialCode: "+993",
    nameAr: "(+993) تركمنستان",
    nameEn: "Turkmenistan (+993)",
    noCodeNameAr: "تركمنستان",
    noCodeNameEn: "Turkmenistan",
  },
  {
    isoNation: "TN",
    nation: "TUN",
    dialCode: "+216",
    nameAr: "(+216) تونس",
    nameEn: "Tunisia (+216)",
    noCodeNameAr: "تونس",
    noCodeNameEn: "Tunisia",
  },
  {
    isoNation: "TO",
    nation: "TON",
    dialCode: "+676",
    nameAr: "(+676) تونغا",
    nameEn: "Tonga (+676)",
    noCodeNameAr: "تونغا",
    noCodeNameEn: "Tonga",
  },
  {
    isoNation: "TR",
    nation: "TUR",
    dialCode: "+90",
    nameAr: "(+90) تركيا",
    nameEn: "Turkey (+90)",
    noCodeNameAr: "تركيا",
    noCodeNameEn: "Turkey",
  },
  {
    isoNation: "TT",
    nation: "TTO",
    dialCode: "+1",
    nameAr: "(+1868) ترينيداد و توباغو",
    nameEn: "Trinidad and Tobago (+1868)",
    noCodeNameAr: "ترينيداد و توباغو",
    noCodeNameEn: "Trinidad and Tobago",
  },
  {
    isoNation: "TV",
    nation: "TUV",
    dialCode: "+688",
    nameAr: "(+688) توفالو",
    nameEn: "Tuvalu (+688)",
    noCodeNameAr: "توفالو",
    noCodeNameEn: "Tuvalu",
  },
  {
    isoNation: "TW",
    nation: "TWN",
    dialCode: "+886",
    nameAr: "(+886) تايوان (الصين)",
    nameEn: "Taiwan (China) (+886)",
    noCodeNameAr: "تايوان (الصين)",
    noCodeNameEn: "Taiwan (China)",
  },
  {
    isoNation: "TZ",
    nation: "TZA",
    dialCode: "+255",
    nameAr: "(+255) تانزانيا",
    nameEn: "Tanzania (+255)",
    noCodeNameAr: "تانزانيا",
    noCodeNameEn: "Tanzania",
  },
  {
    isoNation: "UA",
    nation: "UKR",
    dialCode: "+380",
    nameAr: "(+380) أوكرانيا",
    nameEn: "Ukraine (+380)",
    noCodeNameAr: "أوكرانيا",
    noCodeNameEn: "Ukraine",
  },
  {
    isoNation: "UG",
    nation: "UGA",
    dialCode: "+256",
    nameAr: "(+256) أوغندا",
    nameEn: "Uganda (+256)",
    noCodeNameAr: "أوغندا",
    noCodeNameEn: "Uganda",
  },
  {
    isoNation: "US",
    nation: "USA",
    dialCode: "+1",
    nameAr: "(+1) الولايات المتحدة الأميريكية",
    nameEn: "United States (+1)",
    noCodeNameAr: "الولايات المتحدة الأميريكية",
    noCodeNameEn: "United States",
  },
  {
    isoNation: "UY",
    nation: "URY",
    dialCode: "+598",
    nameAr: "(+598) الأورغواي",
    nameEn: "Uruguay (+598)",
    noCodeNameAr: "الأورغواي",
    noCodeNameEn: "Uruguay",
  },
  {
    isoNation: "UZ",
    nation: "UZB",
    dialCode: "+998",
    nameAr: "(+998) أوزباكستان",
    nameEn: "Uzbekistan (+998)",
    noCodeNameAr: "أوزباكستان",
    noCodeNameEn: "Uzbekistan",
  },
  {
    isoNation: "VA",
    nation: "VAT",
    dialCode: "+379",
    nameAr: "(+379) الفاتيكان",
    nameEn: "Vatican City (+379)",
    noCodeNameAr: "الفاتيكان",
    noCodeNameEn: "Vatican City",
  },
  {
    isoNation: "VC",
    nation: "VCT",
    dialCode: "+1",
    nameAr: "(+1784) سانت فينسنت والغرينادين",
    nameEn: "Saint Vincent and the Grenadines (+1784)",
    noCodeNameAr: "سانت فينسنت والغرينادين",
    noCodeNameEn: "Saint Vincent and the Grenadines",
  },
  {
    isoNation: "VE",
    nation: "VEN",
    dialCode: "+58",
    nameAr: "(+58) فينزويلا",
    nameEn: "Venezuela (+58)",
    noCodeNameAr: "فينزويلا",
    noCodeNameEn: "Venezuela",
  },
  {
    isoNation: "VG",
    nation: "VGB",
    dialCode: "+1",
    nameAr: "(+1284) جزر العذراء البريطانية",
    nameEn: "Virgin Islands (British) (+1284)",
    noCodeNameAr: "جزر العذراء البريطانية",
    noCodeNameEn: "Virgin Islands (British)",
  },
  {
    isoNation: "VI",
    nation: "VIR",
    dialCode: "+1",
    nameAr: "(+1340) جزر العذراء الأمريكية",
    nameEn: "Virgin Islands (U.S.) (+1340)",
    noCodeNameAr: "جزر العذراء الأمريكية",
    noCodeNameEn: "Virgin Islands (U.S.)",
  },
  {
    isoNation: "VN",
    nation: "VNM",
    dialCode: "+84",
    nameAr: "(+84) فيتنام",
    nameEn: "Vietnam (+84)",
    noCodeNameAr: "فيتنام",
    noCodeNameEn: "Vietnam",
  },
  {
    isoNation: "VU",
    nation: "VUT",
    dialCode: "+678",
    nameAr: "(+678) فانواتو",
    nameEn: "Vanuatu (+678)",
    noCodeNameAr: "فانواتو",
    noCodeNameEn: "Vanuatu",
  },
  {
    isoNation: "WF",
    nation: "WLF",
    dialCode: "+681",
    nameAr: "(+681) جزر واليس وفوتونا",
    nameEn: "Wallis and Futuna (+681)",
    noCodeNameAr: "جزر واليس وفوتونا",
    noCodeNameEn: "Wallis and Futuna",
  },
  {
    isoNation: "WS",
    nation: "WSM",
    dialCode: "+685",
    nameAr: "(+685) ساموا",
    nameEn: "Samoa (+685)",
    noCodeNameAr: "ساموا",
    noCodeNameEn: "Samoa",
  },
  {
    isoNation: "XK",
    nation: "XKX",
    dialCode: "+383",
    nameAr: "(+383) كوسوفو",
    nameEn: "Kosovo (+383)",
    noCodeNameAr: "كوسوفو",
    noCodeNameEn: "Kosovo",
  },
  {
    isoNation: "YE",
    nation: "YEM",
    dialCode: "+967",
    nameAr: "(+967) اليمن",
    nameEn: "Yemen (+967)",
    noCodeNameAr: "اليمن",
    noCodeNameEn: "Yemen",
  },
  {
    isoNation: "YT",
    nation: "MYT",
    dialCode: "+262",
    nameAr: "(+262) مايوت",
    nameEn: "Mayotte (+262)",
    noCodeNameAr: "مايوت",
    noCodeNameEn: "Mayotte",
  },
  {
    isoNation: "ZA",
    nation: "ZAF",
    dialCode: "+27",
    nameAr: "(+27) جنوب أفريقيا",
    nameEn: "South Africa (+27)",
    noCodeNameAr: "جنوب أفريقيا",
    noCodeNameEn: "South Africa",
  },
  {
    isoNation: "ZM",
    nation: "ZMB",
    dialCode: "+260",
    nameAr: "(+260) زامبيا",
    nameEn: "Zambia (+260)",
    noCodeNameAr: "زامبيا",
    noCodeNameEn: "Zambia",
  },
  {
    isoNation: "ZW",
    nation: "ZWE",
    dialCode: "+263",
    nameAr: "(+263) زيمبابوي",
    nameEn: "Zimbabwe (+263)",
    noCodeNameAr: "زيمبابوي",
    noCodeNameEn: "Zimbabwe",
  },
];
